












import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Outcome from '@/components/Outcome.vue'
import { LocaleMessages } from 'vue-i18n'
interface IText {
  title: LocaleMessages | string
  description: LocaleMessages | string
}
interface IHighlights {
  descriptionTextKey: string
  iconSrc: string
  status: number
  titleTextKey: string
}
@Component({
  components: {
    ContentArea,
    Outcome
  }
})
export default class Negative extends Vue {
  private outcomeContent: IText = {
    title: '',
    description: ''
  }

  private created (): void {
    let isHighlights = JSON.parse(sessionStorage.getItem('highlights') || '')
    isHighlights = isHighlights.filter((el: IHighlights) => el.status)
    if (isHighlights.length) {
      this.outcomeContent.title = this.$t('outcome.negative[0].title')
      this.outcomeContent.description = this.$t('outcome.negative[0].description')
    } else {
      this.outcomeContent.title = this.$t('outcome.negative[1].title')
      this.outcomeContent.description = this.$t('outcome.negative[1].description')
    }
  }
}
